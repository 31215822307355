<template>
  <div class="card">
    <h3 class="card-title">LISTAR CATEGORÍAS</h3>
    <div v-if="categorias">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="categorias"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        :actionButton2="actionButton2"
        @rowClicked="showCategoria"
      ></table-list>
    </div>
    <modal v-if="openNew" @close="openNew = false">
      <nueva-categoria slot="modal-body" @saved="refresh"></nueva-categoria>
    </modal>
    <modal v-if="openEditarConvenio" @close="openEditarConvenio = false">
      <editar-convenio slot="modal-body" @saved="refresh"></editar-convenio>
    </modal>
  </div>
</template>
<script>
import { CategoriaServices } from "../services/CategoriaServices";
import TableList from "@/components/elements/TableList";
import Modal from "@/components/elements/Modal";
import NuevaCategoria from "./NuevaCategoria.vue";
import EditarConvenio from './EditarConvenio.vue';
export default {
  components: { TableList, Modal, NuevaCategoria, EditarConvenio },
  name: "ListarCategorias",
  data() {
    return {
      categorias: null,
      headers: CategoriaServices.headers(),
      body: CategoriaServices.body(),
      actionButton: {
        text: "Nueva Categoría",
        action: () => {
          this.openNew = true;
        },
      },
      actionButton2: {
        text: "Editar convenio",
        action: () => {
          this.editarConvenio()
        },
      },
      openNew: false,
      openEditarConvenio: false,
    };
  },
  mounted() {
    CategoriaServices.api.getAll().then((categorias) => (this.categorias = categorias));
  },
  methods: {
    refresh() {
      this.openNew = false;
      this.openEditarConvenio = false;
      CategoriaServices.api.getAll().then((categorias) => {
        this.categorias = categorias;
        this.$refs['tablelist'].refresh(categorias)
      });
    },
    showCategoria(categoria){
      this.$router.push({name:'VerCategoria', params:{id:categoria.id}})
    },
    editarConvenio(){
      this.openEditarConvenio = true
    }
  },
};
</script>