<template>
  <div class="modal-form">
    <h3 class="card-title">Cargar categoria</h3>
    <form @submit.prevent="save">
      <pd-input
        v-model="descripcion"
        id="descripcion"
        label="Nombre"
        placeholder="Ingrese el nombre de la categoría"
        class="my-1"
        :required="enviado && !$v.descripcion.required"
      ></pd-input>
      <pd-input
        v-model="abreviatura"
        id="abreviatura"
        label="Abreviatura"
        placeholder="Ingrese la abreviatura de la categoría"
        class="my-1"
        :required="enviado && !$v.abreviatura.required"
      ></pd-input>
      <div class="form-group my-1">
        <label class="form-label">Convenio</label>
        <div class="form-control radio-container">
          <input type="checkbox" id="convenio" value="true" v-model="convenio" />
          <label style="padding-left:.5rem;" for="convenio">¿Pertenece a convenio?</label>
        </div>
      </div>
      <pd-input
        v-model="coeficiente"
        id="coeficiente"
        inputmode="numeric"
        type="number"
        label="Coeficiente"
        placeholder="Ingrese el coeficiente de la categoría"
        class="my-1"
        :required="enviado && !$v.coeficiente.required"
      ></pd-input>
      <pd-input
        v-model="basico"
        id="basico"
        inputmode="numeric"
        type="number"
        label="Básico de convenio"
        placeholder="Ingrese el básico de la categoría"
        class="my-1"
        :required="enviado && !$v.basico.required"
      ></pd-input>
      <pd-input
        v-model="total_calculado"
        id="total_calculado"
        inputmode="numeric"
        type="number"
        label="Sueldo básico calculado"
        placeholder="Básico calculado"
        class="my-1"
        :readonly="true"
        classInput="form-control readonly"
      ></pd-input>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import { CategoriaServices } from "../services/CategoriaServices";
import Swal from "sweetalert2";
export default {
  name: "NuevaCategoria",
  data() {
    return {
      descripcion: "",
      abreviatura: "",
      coeficiente: null,
      basico: null,
      total_calculado: 0,
      enviado: false,
      convenio: false,
    };
  },
  watch: {
    coeficiente() {
      if (this.coeficiente) {
        if (this.basico) {
          this.total_calculado = this.basico * this.coeficiente;
          return;
        }
      }
      return;
    },
    basico() {
      if (this.basico) {
        if (this.coeficiente) {
          this.total_calculado = this.basico * this.coeficiente;
          return;
        }
      }
      return;
    },
  },
  validations: {
    ...CategoriaServices.validations,
  },
  methods: {
    save() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      CategoriaServices.api
        .save({
          descripcion: this.descripcion,
          abreviatura: this.abreviatura,
          convenio: this.convenio,
          coeficiente: this.coeficiente,
          basico: this.basico,
          total: this.total_calculado,
        })
        .then((r) => {
          Swal.fire(
            "Éxito",
            `<p><b>${r.categoria.descripcion}</b> se ha guardado con éxito</p>`,
            "success"
          );
          this.$emit("saved");
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
  },
};
</script>