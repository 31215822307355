import Axios from 'axios'
import { required } from "vuelidate/lib/validators";

export const CategoriaServices = {
    //API
    api: {
        async find(id) {
            return Axios.get('categorias/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('categorias')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(categoria) {
            return Axios.post('categorias', categoria)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, categoria) {
            return Axios.put('categorias/' + id, categoria)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(categoria) {
            return Axios.delete('categorias/' + categoria.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async findConvenio() {
            return Axios.get('categorias/basico-convenio')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async saveConvenio(params) {
            return Axios.post('categorias/basico-convenio', params)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        }
    },
    //VALIDATIONS
    validations: {
        descripcion: { required },
        coeficiente: { required },
        basico: { required },
        abreviatura: { required },
    },
    //TABLELIST
    headers() {
        const header = [{ value: '#', sortable: true }, { value: 'Abreviatura', sortable: true }, { value: 'Descripcion', sortable: true }, { value: 'Coeficiente', sortable: true }, { value: 'Básico', sortable: true }, { value: 'Total', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'id', class: 'text-primary', label: '#' }]
            },
            {
                class: 'important',
                data: [{ value: 'abreviatura', label: "Abrev" }]
            },
            {
                class: 'important',
                data: [{ value: 'descripcion', label: "Descripción" }]
            },
            {
                class: 'important',
                data: [{ value: 'coeficiente', label: "Coeficiente", mutator: (item) => { return item.toFixed(2) } }]
            },
            {
                class: 'important',
                data: [{ value: 'basico', label: "Básico", mutator: (item) => { return this.numberFormat(item) } }]
            },
            {
                class: 'important',
                data: [{ value: 'total', label: "Total", mutator: (item) => { return this.numberFormat(item) } }]
            }
        ]
        return body
    },
    dataList() {
        return [
            { name: "descripcion", class: "" }
        ]
    },
    numberFormat(val) {
        const options2 = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options2);
        return numberFormat.format(val)
    }

}