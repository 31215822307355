<template>
  <div class="modal-form">
    <h3 class="card-title">Editar básico de convenio</h3>
    <form @submit.prevent="save">
      <pd-input
        v-model="basico_actual"
        id="basico_actual"
        type="text"
        label="Sueldo básico actual"
        placeholder="Básico actual"
        class="my-1"
        :readonly="true"
        classInput="form-control readonly"
      ></pd-input>
      <pd-input
        v-model="basico_nuevo"
        id="basico_nuevo"
        inputmode="numeric"
        type="text"
        label="Nuevo básico de convenio"
        placeholder="Ingrese el básico"
        class="my-1"
        :required="enviado && !$v.basico_nuevo.required"
      ></pd-input>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import { CategoriaServices } from "../services/CategoriaServices";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  name: "EditarCategoria",
  data() {
    return {
      basico_nuevo: null,
      basico_actual: null,
      enviado: false,
    };
  },
  validations: {
    basico_nuevo: { required },
  },
  mounted() {
    CategoriaServices.api
      .findConvenio()
      .then((r) => {
        this.basico_actual = CategoriaServices.numberFormat(r.basico);
      })
      .catch((e) => console.log(e));
  },
  methods: {
    save() {
      CategoriaServices.api
        .saveConvenio({
          basico: this.basico_nuevo,
        })
        .then((r) => {
          if(r.success){
            Swal.fire(
              "Éxito",
              `<p>El convenio se ha modificado con éxito</p>`,
              "success"
            );
            this.$emit("saved");
          }else{
            Swal.fire(
              "Error",
              `<p>No se pudo completar la acción</p>`,
              "error"
            );
          }
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
  },
};
</script>